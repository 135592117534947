import React from "react"
import styled from "styled-components"

function Career() {
  return (
    <Wrapper>
      <Title>Career</Title>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Title = styled.h1``

export default Career
